import React from 'react'
import './Sidebar.css'

import Clock from '../clock/Clock'
import Filter from '../filter/Filter'
import FactionStats from '../faction-stats/FactionStats'

import * as labels from '../../../assets/labels.json'

/**
 * This component houses the search, filter functions and displays the selected faction information
 */
class Sidebar extends React.Component {

    constructor() {
        super()
        this.state = { searchTerm: '' }
    }

    render() {
        return (
            <div className="sidebar">
                <div className="faction-search">
                    <input type="text"
                        placeholder={labels.galaxyMap.sidebar.factionName}
                        onChange={event => this.onSearchChanged(event, this)}
                        onKeyPress={event => this.onSearchKeyPressed(event, this)}
                        autoFocus={this.props.autofocus} tabIndex="1"/>
                    <button onClick={() => this.onFactionNameEntered(this)}>{labels.galaxyMap.sidebar.search}</button>
                </div>
                { this.renderFactionInfo()}
                { this.renderFilter()}
                <Clock></Clock>
                { this.props.disabled ? this.renderBlackCover() : <></> }
            </div>
        )
    }

    renderFactionInfo() {
        if (this.props.showFactionInfo) {
            return <FactionStats faction={this.props.faction} onSystemSelected={s => this.props.onSystemSelected(s)}></FactionStats>
        } else {
            return <></>
        }
    }

    renderFilter() {
        if (this.props.showFactionInfo) {
            return <Filter
                systems={this.props.faction.systems}
                onFilterChanged={f => this.props.onFilterChanged(f)}
                onError={e => this.props.onError(e)}>
            </Filter>
        } else {
            return <></>
        }
    }

    renderBlackCover() {
        return <div className="sidebar-black-cover"></div>
    }

    onSearchChanged(event, context) {
        context.setState({
            searchTerm: event.target.value
        })
    }

    onSearchKeyPressed(event, context) {
        if (event.which === 13)
            context.onFactionNameEntered(context)
    }

    onFactionNameEntered(context) {
        if (context.state.searchTerm != null && context.state.searchTerm.length > 0)
            context.props.onFactionSearch(context.state.searchTerm)
    }
}

export default Sidebar