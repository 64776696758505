/**
 * Create a 2D array of the given list of systems, based on their Elite: Dangerous coordinates
 */
export function convertSystemsToGrid(systems) {
    let gridSize = findGridSizePerSide(systems)
    let grid = createRandomGridOf(gridSize, systems)

    let positionsSwitched = 999
    while (positionsSwitched > 0) {
        positionsSwitched = 0

        for (let y = 0; y < gridSize; y++) {
            for (let x = 0; x < gridSize; x++) {
                const me = grid[y][x]

                if (me != null) {
                    const neighbours = {
                        top: grid[y - 1] != null ? grid[y - 1][x] : null,
                        left: grid[y][x - 1]
                    }

                    if (neighbours.top != null && neighbours.top.coordinates.y > me.coordinates.y) {
                        grid[y - 1][x] = me
                        grid[y][x] = neighbours.top

                        positionsSwitched++
                    } else if (neighbours.left != null && neighbours.left.coordinates.x > me.coordinates.x) {
                        grid[y][x - 1] = me
                        grid[y][x] = neighbours.left

                        positionsSwitched++
                    }
                }
            }
        }
    }

    return grid
}

/**
 * A grid must be equal length on X and Y, 
 * return a minimum width/length that fits all given systems
 */
function findGridSizePerSide(systems) {
    let size = 1

    while (size * size < systems.length) {
        size++
    }

    return size
}

/**
 * Create a grid of a given size on each side, filled with the given systems
 * 
 * @param {*} size The size of the grid on the X and/or Y side
 * @param {*} systems These systems will be placed "randomly"
 */
function createRandomGridOf(size, systems) {
    let grid = [],
        index = 0

    for (let y = 0; y < size; y++) {
        grid[y] = []

        for (let x = 0; x < size; x++) {
            if (systems[index] != null) {
                grid[y][x] = systems[index]
                index++
            }
        }
    }

    return grid
}