export function log(message) {
    console.log(`[${new Date().toISOString()}] ${parseMessage(message)}`)
}

export function logError(message) {
    console.error(`[${new Date().toISOString()}] ${parseMessage(message)}`)
}

export function parseMessage(message) {
    if (message == null) {
        return 'An unknown error has occurred...'
    } else if (typeof message === 'string') {
        try {
            let json = JSON.parse(message)
            return parseMessageAsObject(json)
        } catch (ignored) {
            return message
        }
    } else {
        return parseMessageAsObject(message)
    }
}

function parseMessageAsObject(obj) {
    if (obj.message != null) {
        if (typeof obj.message === 'string') {
            return obj.message
        } else if (typeof obj.message === 'object') {
            return parseMessageAsObject(obj.message)
        } else {
            return obj.message.toString()
        }
    } else if (obj.error != null) {
        if (typeof obj.error === 'string') {
            return obj.error
        } else if (typeof obj.error === 'object') {
            return parseMessageAsObject(obj.error)
        } else {
            return obj.error.toString()
        }
    } else {
        return 'An unknown error has occurred...'
    }
}