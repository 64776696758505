import React from 'react'
import './Filter.css'

import { getStateIdByName } from '../../../helpers/StateHelper'

import * as labels from '../../../assets/labels.json'

class Filter extends React.Component {

    constructor() {
        super()

        this.state = {
            systemNameFilterInput: null,
            statesFilterInput: null,
            factionSpecificOption: true
        }
    }

    render() {
        return <div className="filter desktop-only">
            <input type="text" value={this.state.systemNameFilterInput || ''}
                placeholder={labels.galaxyMap.sidebar.systemName}
                onChange={event => this.onSystemFilterChanged(event, this)}
                onKeyPress={event => this.onInputKeyPressed(event, this)}
                tabIndex="2" />

            <input type="text" value={this.state.statesFilterInput || ''}
                placeholder={labels.galaxyMap.sidebar.states}
                onChange={event => this.onStatesFilterChanged(event, this)}
                onKeyPress={event => this.onInputKeyPressed(event, this)}
                tabIndex="3" />

            <div className="filter-checkbox-container has-input-background" onClick={() => this.onFactionSpecificOptionClicked(this)}>
                <button className="filter-checkbox" >
                    {this.state.factionSpecificOption ? '✓' : ' '}
                </button>
                <label className="filter-checkbox-label">{labels.galaxyMap.sidebar.filterFactionSpecific}</label>
            </div>

            <button onClick={() => this.applyFilter(this)}>{labels.galaxyMap.sidebar.applyFilter}</button>
            <button onClick={() => this.unapplyAllFilters(this)}>{labels.galaxyMap.sidebar.removeFilter}</button>
        </div>
    }

    onSystemFilterChanged(event, context) {
        context.setState({
            systemNameFilterInput: event.target.value
        })
    }

    onStatesFilterChanged(event, context) {
        context.setState({
            statesFilterInput: event.target.value
        })
    }

    onFactionSpecificOptionClicked(context) {
        context.setState({
            factionSpecificOption: !context.state.factionSpecificOption
        })
    }

    onInputKeyPressed(event, context) {
        if (event.which === 13) {
            context.applyFilter(context)
        }
    }

    applyFilter(context) {
        let filter = {
            systemName: context.state.systemNameFilterInput ? context.state.systemNameFilterInput.toLowerCase() : null,
            states: context.state.statesFilterInput ? context.state.statesFilterInput.split(',').map(s => getStateIdByName(s.trim())) : null,
            onlyForSelectedFaction: context.state.factionSpecificOption
        }

        if (filter.states != null && filter.states.indexOf(-1) > -1) {
            context.props.onError({ error: labels.galaxyMap.sidebar.filterStateError })
        } else {
            context.props.onFilterChanged(filter)
        }
    }

    unapplyAllFilters(context) {
        context.props.onFilterChanged({})

        context.setState({
            systemNameFilterInput: null,
            statesFilterInput: null
        })
    }
}

export default Filter
