import React from 'react'
import './SystemTile.css'

import backgroundImage from '../../../assets/images/system_tile.jpg'
import fleetCarrierIcon from '../../../assets/images/station_carrier_cutout.png'
import whiteStarIcon from '../../../assets/images/star_square_white.png'
import orangeStarIcon from '../../../assets/images/star_square_orange.png'
import blueStarIcon from '../../../assets/images/star_square_blue.png'
import influenceUpIcon from '../../../assets/images/influence_up.png'
import influenceDownIcon from '../../../assets/images/influence_down.png'

import { getColorForFactionRank, isInfluenceGrowing, isInfluenceDropping, getInfluenceChangePercentageString } from '../../../helpers/InfluenceHelper'
import { getAlertsForStates } from '../../../helpers/StateHelper'
import { getEconomyNameById } from '../../../helpers/EconomyHelper'
import { hasFleetCarriers } from '../../../helpers/StationHelper'

import * as labels from '../../../assets/labels.json'

/**
 * This component is a simple tile displayed on the board that shows system names and influence, with appropriate colours
 */
class SystemTile extends React.Component {

    constructor() {
        super()
        this.state = { hovering: false }
    }

    render() {
        let color = getColorForFactionRank(this.props.selectedFaction.id, this.props.system.factions),
            className = `system-tile ${this.props.unfocus ? 'unfocus' : ''}`,
            factionInSystem = this.props.system.factions.find(f => f.id === this.props.selectedFaction.id)

        return <div
            className={className}
            style={{ backgroundImage: `url('${backgroundImage}')` }}
            onClick={() => this.onClick(this)}
            onMouseEnter={() => this.onHover(true, this)}
            onMouseLeave={() => this.onHover(false, this)}>

            <img
                className={`system-tile-star ${this.getRandomGlowClass()}`}
                src={this.getRandomIcon()}
                alt={this.props.system.name} />

            <p
                className='system-tile-label'
                style={{ color: color, fontSize: `${this.getSystemNameSizePercent(this.props.system.name)}%` }}>
                {this.props.system.name}
            </p>

            <div className='system-tile-influence'
                style={{
                    width: `${this.props.selectedFaction.influence}%`,
                    background: color
                }}></div>

            {this.renderAlert(factionInSystem)}
            {this.renderInfluenceGrowth(factionInSystem)}
            {this.renderFleetCarrierPresence(color)}
            {this.renderEconomyBadge()}
        </div>
    }

    renderInfluenceGrowth(factionInSystem) {
        let delta = getInfluenceChangePercentageString(factionInSystem)

        if (isInfluenceGrowing(factionInSystem)) {
            return <img src={influenceUpIcon} alt={delta} className="system-tile-influence-growth-icon" />
        } else if (isInfluenceDropping(factionInSystem)) {
            return <img src={influenceDownIcon} alt={delta} className="system-tile-influence-growth-icon" />
        } else {
            return <></>
        }
    }

    renderAlert(factionInSystem) {
        let alerts = getAlertsForStates(factionInSystem.activeStates, factionInSystem.pendingStates, factionInSystem.recoveringStates)

        if (alerts.length > 0) {
            let topAlert = alerts[0]

            return <div className="system-alert">
                <h3 className="system-alert-exclamation" style={{ color: topAlert.background }}>!</h3>
                {this.state.hovering ? <p className="system-alert-text top" style={{ background: topAlert.background, color: topAlert.color }}>{topAlert.text}</p> : <></>}
            </div>
        } else {
            return <></>
        }
    }

    renderFleetCarrierPresence(color) {
        if (hasFleetCarriers(this.props.system.stations)) {
            return <div className="system-tile-carrier" style={{ background: color }}>
                <img src={fleetCarrierIcon} alt={labels.galaxyMap.systemView.fleetCarrierPresent} className="system-tile-carrier-icon" />
            </div>
        } else {
            return <></>
        }
    }

    renderEconomyBadge() {
        if (this.state.hovering) {
            return <p
                className="system-alert-text bottom">
                {getEconomyNameById(this.props.system.economyId)}
            </p>
        } else {
            return <></>
        }
    }

    onHover(hovering, context) {
        context.setState({
            hovering: hovering
        })
    }

    onClick(context) {
        context.props.onClick()
    }

    getSystemNameSizePercent(name) {
        if (name.length > 20)
            return 80
        else
            return 100
    }

    getRandomIcon() {
        let randomNumber = Math.random()

        if (randomNumber < 0.33) {
            return whiteStarIcon
        } else if (randomNumber < 0.66) {
            return orangeStarIcon
        } else {
            return blueStarIcon
        }
    }

    getRandomGlowClass() {
        let randomNumber = Math.random()

        if (randomNumber < 0.33) {
            return 'slow'
        } else if (randomNumber < 0.66) {
            return 'normal'
        } else {
            return 'fast'
        }
    }
}

export default SystemTile
