import React from 'react'
import './Board.css'
import SystemTile from '../system-tile/SystemTile'

import { convertSystemsToGrid } from '../../../helpers/GridHelper'
import { getDistinctStatesInSystem } from '../../../helpers/StateHelper'

/**
 * This component renders all the system tiles
 */
class Board extends React.Component {

    render() {
        let grid = convertSystemsToGrid(this.props.systems),
            filterFactionId = this.props.filter.onlyForSelectedFaction ? this.props.selectedFaction.id : null

        return (
            <div className="board">
                {grid.filter(l => l.length > 0).map(l => this.renderLayer(l, filterFactionId))}
            </div>
        )
    }

    renderLayer(layer, filterFactionId) {
        let key = `Layer_${layer[0].id}`

        return <div key={key}>
            <div className="layer" style={{ width: `${layer.length * this.getEstimatedTileWidth()}px` }}>
                {layer.map(s => this.renderSystem(s, filterFactionId))}
            </div>
            <hr className="layer-separator"/>
        </div>
    }

    renderSystem(system, filterFactionId) {
        let matchesFilter = this.matchesFilter(system, this.props.filter, filterFactionId)

        return <SystemTile
            key={system.id}
            unfocus={!matchesFilter}
            selectedFaction={this.props.selectedFaction}
            system={system}
            onClick={() => this.onSystemSelected(system, this)}></SystemTile>
    }

    matchesFilter(system, filter, factionId) {
        if (filter.systemName && !this.satisfiesNameFilter(system, filter.systemName)) {
            return false
        } else if (filter.states && !this.satisfiesStateFilter(system, filter.states, factionId)) {
            return false
        } else {
            return true
        }
    }

    satisfiesNameFilter(system, query) {
        return system.name.toLowerCase().indexOf(query) > -1
    }

    satisfiesStateFilter(system, states, factionId) {
        return getDistinctStatesInSystem(system, factionId).some(s => states.indexOf(s) > -1)
    }

    onSystemSelected(system, context) {
        context.props.onSystemSelected(system)
    }

    getEstimatedTileWidth() {
        return 136
    }
}

export default Board
