import React from 'react'
import './GalaxyMap.css'

import { select } from '../../helpers/LambdaHelper'
import { hasStored, getFromStorage, store } from '../../helpers/LocalStorageHelper'
import { log } from '../../helpers/LoggerHelper'

import Sidebar from './sidebar/Sidebar'
import Loader from '../loader/Loader'
import Board from './board/Board'
import SystemView from './system-view/SystemView'
import Error from '../error/Error'

const STORED_STATE_EXPIRY_MILLIS = 3600000

export const galaxyMapUrl = '/galaxy-map'
export const galaxyMapWithVariableUrl = '/galaxy-map/:searchInput'

export class GalaxyMap extends React.Component {

    constructor() {
        super()
        this.state = this.getDefaultState()
    }

    render() {
        return (
            <div className="map">
                <div className="section-a">
                    {this.renderSidebar()}
                </div>
                <div className="section-b">
                    {this.renderBoard()}
                    {this.renderSystemView()}
                    {this.renderLoader()}
                    {this.renderErrorMessage()}
                </div>
            </div>
        )
    }

    renderSidebar() {
        if (this.state.loading) {
            return <Sidebar disabled autofocus={false}></Sidebar>
        } else if (this.hasSelection()) {
            return <Sidebar
                faction={this.state.selectedFaction}
                onFactionSearch={name => this.selectFaction(name, this)}
                onSystemSelected={system => this.selectSystem(system, this)}
                onFilterChanged={f => this.applyFilter(f, this)}
                onError={e => this.handleError(this, e)}
                showFactionInfo>
            </Sidebar>
        } else {
            return <Sidebar autofocus={true} onFactionSearch={name => this.selectFaction(name, this)}></Sidebar>
        }
    }

    renderBoard() {
        if (this.hasSelection()) {
            return <Board
                selectedFaction={this.state.selectedFaction}
                systems={this.state.systems}
                filter={this.state.appliedFilter}
                onSystemSelected={system => this.selectSystem(system, this)}></Board>
        } else {
            return <></>
        }
    }

    renderSystemView() {
        if (this.isViewingSystem()) {
            return <SystemView
                system={this.state.selectedSystem}
                selectedFaction={this.state.selectedFaction}
                onClosed={() => this.unselectSystem(this)}
                onFactionClicked={faction => this.selectFaction(faction.name, this)}></SystemView>
        } else {
            return <></>
        }
    }

    renderLoader() {
        if (this.hasSelection() && !this.state.loading) {
            return <></>
        } else {
            return <Loader isLoading={this.state.loading}></Loader>
        }
    }

    renderErrorMessage() {
        if (this.hasError()) {
            return <Error message={this.state.error} onHide={() => this.hideErrorMessage(this)}></Error>
        } else {
            return <></>
        }
    }

    selectFaction(name, context) {
        context.showLoader()
        context.unselectSystem(context);

        select(name).then(res => {
            context.hideLoader()

            log(`Stored a session for ${res.name}`)
            store('galaxyMapSelection', {
                systems: res.systems,
                selectedFaction: res,
                timestamp: Date.now()
            })

            context.setState({
                systems: res.systems,
                selectedFaction: res
            })
        }).catch(err => {
            context.hideLoader()
            context.handleError(context, err)
        })
    }

    selectSystem(system, context) {
        context.setState({
            selectedSystem: system
        })
    }

    unselectSystem(context) {
        context.setState({
            selectedSystem: null
        })
    }

    applyFilter(filter, context) {
        if (filter.error) {
            context.handleError(context, { error: filter.error })
        } else {
            context.setState({
                appliedFilter: filter
            })
        }
    }

    getDefaultState() {
        return {
            systems: [],
            selectedFaction: null,
            selectedSystem: null,
            appliedFilter: {},
            loading: false,
            error: null
        }
    }

    tryLoadingStoredState() {
        if (hasStored('galaxyMapSelection')) {
            let stored = getFromStorage('galaxyMapSelection'),
                timestamp = stored.timestamp

            let now = Date.now() - STORED_STATE_EXPIRY_MILLIS
            if (timestamp > now) {
                this.setState(stored)
                log(`Recovered a saved session for ${stored.selectedFaction.name}`)
            } else {
                log(`Saved session for ${stored.selectedFaction.name} was too old (${stored.timestamp})`)
            }
        } else {
            log('No saved session found')
        }
    }

    handleError(context, error) {
        context.setState({
            error: error
        })
    }

    hideErrorMessage(context) {
        context.setState({
            error: null
        })
    }

    showLoader() {
        this.setState({
            loading: true
        })
    }

    hideLoader() {
        this.setState({
            loading: false
        })
    }

    hasSelection() {
        return this.state.selectedFaction != null && this.state.systems != null
    }

    isViewingSystem() {
        return this.state.selectedSystem != null
    }

    hasError() {
        return this.state.error != null
    }

    componentDidMount() {
        if (this.props.searchInput != null && this.props.searchInput.length > 0) {
            this.selectFaction(this.props.searchInput, this)
        } else {
            this.tryLoadingStoredState()
        }
    }
}
