import * as labels from '../assets/labels.json'

const SECURITY_ANARCHY = 64
const SECURITY_LOW = 16
const SECURITY_MEDIUM = 32
const SECURITY_HIGH = 48

export function getSecurityNameById(id) {
    switch (id) {
        case SECURITY_ANARCHY:
            return labels.security.anarchy
        case SECURITY_LOW:
            return labels.security.lowSecurity
        case SECURITY_MEDIUM:
            return labels.security.mediumSecurity
        case SECURITY_HIGH:
            return labels.security.highSecurity
        default:
            return `${labels.security.unknownSecurity} (${id})`
    }
}

export function getSecurityNoteById(id) {
    switch (id) {
        case SECURITY_ANARCHY:
            return labels.announcements.securityAnarchyNote
        case SECURITY_LOW:
            return labels.announcements.securityLowNote
        case SECURITY_MEDIUM:
            return labels.announcements.securityMediumNote
        case SECURITY_HIGH:
            return labels.announcements.securityHighNote
        default:
            return `${labels.security.unknownSecurity} (${id})`
    }
}
