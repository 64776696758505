import * as labels from '../assets/labels.json'

import outpostIcon from '../assets/images/station_outpost_cutout.png'
import coriolisIcon from '../assets/images/station_coriolis_cutout.png'
import orbisIcon from '../assets/images/station_orbis_cutout.png'
import ocellusIcon from '../assets/images/station_ocellus_cutout.png'
import asteroidIcon from '../assets/images/station_asteroid_cutout.png'
import planetaryPortIcon from '../assets/images/station_planetary_port_cutout.png'
import planetaryOutpostIcon from '../assets/images/station_planetary_outpost_cutout.png'
import fleetCarrierIcon from '../assets/images/station_carrier_cutout.png'
import megaShipIcon from '../assets/images/station_megaship_cutout.png'
import odysseySettlementIcon from '../assets/images/station_odyssey_surface_settlement_cutout.png'

const TYPE_CIVILIAN_OUTPOST = 1
const TYPE_COMMERCIAL_OUTPOST = 2
const TYPE_CORIOLIS_STARPORT = 3
const TYPE_INDUSTRIAL_OUTPOST = 4
const TYPE_MILITARY_OUTPOST = 5
const TYPE_MINING_OUTPOST = 6
const TYPE_OCELLUS_STAPORT = 7
const TYPE_ORBIS = 8
const TYPE_SCIENTIFIC_OUTPOST = 9
const TYPE_UNKNOWN_OUTPOST = 11
const TYPE_PLANETARY_OUTPOST = 13
const TYPE_PLANETARY_PORT = 14
const TYPE_MEGASHIP = 19
const TYPE_ASTEROID_BASE = 20
const TYPE_FLEET_CARRIER = 24
const TYPE_ODYSSEY_SURFACE_SETTLEMENT = 25

export function getStationTypeNameById(id) {
    switch (id) {
        case TYPE_CIVILIAN_OUTPOST:
            return labels.stations.civilianOutpost
        case TYPE_COMMERCIAL_OUTPOST:
            return labels.stations.commercialOutpost
        case TYPE_CORIOLIS_STARPORT:
            return labels.stations.coriolisStarport
        case TYPE_INDUSTRIAL_OUTPOST:
            return labels.stations.industrialOutpost
        case TYPE_MILITARY_OUTPOST:
            return labels.stations.militaryOutpost
        case TYPE_MINING_OUTPOST:
            return labels.stations.miningOutpost
        case TYPE_SCIENTIFIC_OUTPOST:
            return labels.stations.scientificOutpost
        case TYPE_UNKNOWN_OUTPOST:
            return labels.stations.unknownOutpost
        case TYPE_OCELLUS_STAPORT:
            return labels.stations.ocellusStarport
        case TYPE_ORBIS:
            return labels.stations.orbisStarport
        case TYPE_PLANETARY_OUTPOST:
            return labels.stations.planetaryOutpost
        case TYPE_PLANETARY_PORT:
            return labels.stations.planetaryPort
        case TYPE_ASTEROID_BASE:
            return labels.stations.asteroidBase
        case TYPE_FLEET_CARRIER:
            return labels.stations.fleetCarrier
        case TYPE_MEGASHIP:
            return labels.stations.megaShip
        case TYPE_ODYSSEY_SURFACE_SETTLEMENT:
            return labels.stations.odysseySurfaceSettlement
        default:
            return `${labels.stations.unknownStation} (${id})`
    }
}

export function getStationIconById(id) {
    switch (id) {
        case TYPE_CIVILIAN_OUTPOST:
        case TYPE_COMMERCIAL_OUTPOST:
        case TYPE_INDUSTRIAL_OUTPOST:
        case TYPE_MILITARY_OUTPOST:
        case TYPE_MINING_OUTPOST:
        case TYPE_SCIENTIFIC_OUTPOST:
        case TYPE_UNKNOWN_OUTPOST:
            return outpostIcon
        case TYPE_CORIOLIS_STARPORT:
            return coriolisIcon
        case TYPE_OCELLUS_STAPORT:
            return ocellusIcon
        case TYPE_ORBIS:
            return orbisIcon
        case TYPE_PLANETARY_OUTPOST:
            return planetaryOutpostIcon
        case TYPE_ODYSSEY_SURFACE_SETTLEMENT:
            return odysseySettlementIcon
        case TYPE_PLANETARY_PORT:
            return planetaryPortIcon
        case TYPE_ASTEROID_BASE:
            return asteroidIcon
        case TYPE_FLEET_CARRIER:
            return fleetCarrierIcon
        case TYPE_MEGASHIP:
            return megaShipIcon
        default:
            return `${labels.stations.unknownStation} (${id})`
    }
}

/**
 * A special station is one that is not controlled by any of the system's minor factions
 */
export function getSpecialStations(system) {
    let systemFactionIds = system.factions.map(f => f.id)
    return system.stations.filter(s => systemFactionIds.indexOf(s.controllingFactionId) < 0)
}

export function hasSpecialStations(system) {
    return getSpecialStations(system).length > 0
}

export function hasFleetCarriers(stations) {
    return stations.some(isFleetCarrier)
}

export function isFleetCarrier(station) {
    return station.typeId === TYPE_FLEET_CARRIER
}
