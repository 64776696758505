import React from 'react'
import { Link } from 'react-router-dom'
import Loader from '../loader/Loader'
import Error from '../error/Error'
import { getTopFactions } from '../../helpers/LambdaHelper'
import { formatPopulation } from '../../helpers/PopulationHelper'
import { getColorForStockMarket, getGlobalInfluenceChangePercentageString, isInfluenceDropping } from '../../helpers/InfluenceHelper'
import { hasStored, getFromStorage, store } from '../../helpers/LocalStorageHelper'
import { log } from '../../helpers/LoggerHelper'
import { galaxyMapWithVariableUrl } from '../galaxy-map/GalaxyMap'
import * as labels from '../../assets/labels.json'

import upIcon from '../../assets/images/influence_up.png'
import downIcon from '../../assets/images/influence_down.png'
import './StockMarket.css'

const STORED_STATE_EXPIRY_MILLIS = 3600000
export const stockMarketUrl = "/stock-market"

export class StockMarket extends React.Component {

    constructor() {
        super()
        this.state = { loading: true, error: false }
    }

    render() {
        return <div className="stock-market">
            {this.renderErrorMessage()}
            {this.renderLoader()}
            {this.renderTable()}
        </div>
    }

    renderTable() {
        if (this.state.factions != null) {
            return <table className="stock-market-table">
                <thead>
                    <tr className="emphasize">
                        <td className="first-column">#</td>
                        <td>{labels.stockMarket.faction}</td>
                        <td>{labels.stockMarket.galacticShare}</td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.factions.map((f, i) => {
                        let icon = isInfluenceDropping(f) ? downIcon : upIcon,
                            url = galaxyMapWithVariableUrl.replace(':searchInput', f.name)

                        return <tr key={f.id} style={this.getStyleFor(f)}>
                            <td className="first-column">{i + 1}</td>
                            <td><Link to={url}>{f.name}</Link></td>
                            <td>
                                <Link to={url}>
                                    {formatPopulation(f.influence)}
                                    <img src={icon} alt="growth" className="growth-icon" />
                                    ({this.getInfluenceChangeLabel(f)})
                                </Link>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        } else {
            return <></>
        }
    }

    renderLoader() {
        if (this.state.loading) {
            return <Loader isLoading={true}></Loader>
        } else {
            return <></>
        }
    }

    renderErrorMessage() {
        if (this.state.error) {
            return <Error message={this.state.errorMessage} onHide={() => this.hideErrorMessage(this)}></Error>
        } else {
            return <></>
        }
    }

    hideErrorMessage(context) {
        context.setState({
            error: null
        })
    }

    getInfluenceChangeLabel(faction) {
        if (isInfluenceDropping(faction)) {
            return ` ${getGlobalInfluenceChangePercentageString(faction)}`
        } else {
            return ` +${getGlobalInfluenceChangePercentageString(faction)}`
        }
    }

    getStyleFor(faction) {
        return { color: getColorForStockMarket(faction) }
    }

    getDefaultOrStoredState() {
        if (hasStored('stockMarket')) {
            let stored = getFromStorage('stockMarket'),
                timestamp = stored.timestamp

            let now = Date.now() - STORED_STATE_EXPIRY_MILLIS
            if (timestamp > now) {
                log('Recovered a saved session for the stock market')
                return Promise.resolve(stored.factions)
            } else {
                log(`Saved session for the stock market was too old (${stored.timestamp})`)
            }
        } else {
            log('No saved session found')
        }

        return new Promise((resolve, reject) => {
            getTopFactions()
                .then(factions => {
                    store('stockMarket', { factions: factions, timestamp: Date.now() })
                    log('Stored a session for the stock market')
                    resolve(factions)
                })
                .catch(reject)
        })
    }

    componentDidMount() {
        this.getDefaultOrStoredState().then(factions => {
            this.setState({
                factions,
                loading: false,
                error: false
            })
        }).catch(err => {
            console.error(err)
            this.setState({
                loading: false,
                error: true,
                errorMessage: err
            })
        })
    }
}
