import { hasWarState, getDistinctStatesInSystem, STATE_WAR, STATE_CIVIL_WAR, STATE_PIRATE_ATTACK } from './StateHelper'
import { getSecurityNameById, getSecurityNoteById } from './SecurityHelper'

import * as labels from '../assets/labels.json'

export function getCombatAnnouncements(system) {
    let returnValue = [],
        distinctStates = getDistinctStatesInSystem(system)

    // Security //
    returnValue.push({
        id: system.securityId,
        name: getSecurityNameById(system.securityId),
        note: getSecurityNoteById(system.securityId)
    })

    // War //
    if (hasWarState(distinctStates)) {
        returnValue.push(getWarAnnouncement(distinctStates))
    }

    // Pirate attack //
    if (distinctStates.indexOf(STATE_PIRATE_ATTACK) > -1) {
        returnValue.push({
            id: STATE_PIRATE_ATTACK,
            name: labels.states.pirateAttack,
            note: labels.announcements.pirateAttackCombatNote
        })
    }

    return returnValue
}

function getWarAnnouncement(states) {
    if (states.indexOf(STATE_WAR) > -1) {
        return {
            id: STATE_WAR,
            name: labels.states.war,
            note: labels.announcements.warCombatNote
        }
    } else if (states.indexOf(STATE_CIVIL_WAR) > -1) {
        return {
            id: STATE_CIVIL_WAR,
            name: labels.states.civilWar,
            note: labels.announcements.warCombatNote
        }
    } else {
        return null
    }
}
