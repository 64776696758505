import * as labels from '../assets/labels.json'

// Social
const GOVERNMENT_DEMOCRACY = 96
const GOVERNMENT_COOPERATIVE = 80
const GOVERNMENT_CONFEDERACY = 48
const GOVERNMENT_COMMUNIST = 32

// Corporate
const GOVERNMENT_CORPORATE = 64

// Authoritarian
const GOVERNMENT_DICTATORSHIP = 112
const GOVERNMENT_PATRONAGE = 144
const GOVERNMENT_FEUDAL = 128
const GOVERNMENT_THEOCRACY = 160
const GOVERNMENT_PRISON_COLONY = -1
const GOVERNMENT_PRISON = 208

// Criminal
const GOVERNMENT_ANARCHY = 16
const GOVERNMENT_NONE = 176

export function getGovernmentNameById(id) {
    switch (id) {
        case GOVERNMENT_DEMOCRACY:
            return labels.government.democracy
        case GOVERNMENT_COOPERATIVE:
            return labels.government.cooperative
        case GOVERNMENT_CONFEDERACY:
            return labels.government.confederacy
        case GOVERNMENT_COMMUNIST:
            return labels.government.communist
        case GOVERNMENT_CORPORATE:
            return labels.government.corporate
        case GOVERNMENT_DICTATORSHIP:
            return labels.government.dictatorship
        case GOVERNMENT_PATRONAGE:
            return labels.government.patronage
        case GOVERNMENT_FEUDAL:
            return labels.government.feudal
        case GOVERNMENT_THEOCRACY:
            return labels.government.theocracy
        case GOVERNMENT_PRISON_COLONY:
            return labels.government.prisonColony
        case GOVERNMENT_PRISON:
            return labels.government.prison
        case GOVERNMENT_ANARCHY:
            return labels.government.anarchy
        case null:
        case GOVERNMENT_NONE:
            return labels.government.noGovernment
        default:
            return `${labels.government.unknownGovernment} (${id})`
    }
}

export function isSocialGovernment(id) {
    return [
        GOVERNMENT_DEMOCRACY,
        GOVERNMENT_COOPERATIVE,
        GOVERNMENT_CONFEDERACY,
        GOVERNMENT_COMMUNIST
    ].some(g => g === id)
}

export function isCorporateGovernment(id) {
    return [
        GOVERNMENT_CORPORATE
    ].some(g => g === id)
}

export function isAuthoritarianGovernment(id) {
    return [
        GOVERNMENT_DICTATORSHIP,
        GOVERNMENT_PATRONAGE,
        GOVERNMENT_FEUDAL,
        GOVERNMENT_PRISON_COLONY
    ].some(g => g === id)
}

export function isCriminalGovernment(id) {
    return [
        GOVERNMENT_ANARCHY
    ].some(g => g === id)
}