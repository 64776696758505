import allianceBadgeUrl from '../assets/images/allegiance_alliance.png'
import empireBadgeUrl from '../assets/images/allegiance_empire.png'
import federationBadgeUrl from '../assets/images/allegiance_federation.png'
import independentBadgeUrl from '../assets/images/allegiance_independent.png'
import pilotsBadgeUrl from '../assets/images/allegiance_pilots.png'
import thargoidBadgeUrl from '../assets/images/allegiance_thargoids.png'

import allianceCutoutUrl from '../assets/images/allegiance_alliance_cutout.png'
import empireCutoutUrl from '../assets/images/allegiance_empire_cutout.png'
import federationCutoutUrl from '../assets/images/allegiance_federation_cutout.png'
import independentCutoutUrl from '../assets/images/allegiance_independent_cutout.png'
import pilotsCutoutUrl from '../assets/images/allegiance_pilots_cutout.png'
import thargoidCutoutUrl from '../assets/images/allegiance_thargoids_cutout.png'

import * as labels from '../assets/labels.json'

const ALLEGIANCE_ALLIANCE = 1
const ALLEGIANCE_EMPIRE = 2
const ALLEGIANCE_FEDERATION = 3
const ALLEGIANCE_INDEPENDENT = 4
const ALLEGIANCE_NONE = 5
const ALLEGIANCE_PILOTS_FEDERATION = 7
const ALLEGIANCE_THARGOID = 9

export function getAllegianceNameById(id) {
    switch (id) {
        case ALLEGIANCE_ALLIANCE:
            return labels.allegiance.alliance
        case ALLEGIANCE_EMPIRE:
            return labels.allegiance.empire
        case ALLEGIANCE_FEDERATION:
            return labels.allegiance.federation
        case ALLEGIANCE_INDEPENDENT:
            return labels.allegiance.independent
        case ALLEGIANCE_NONE:
            return labels.allegiance.none
        case ALLEGIANCE_PILOTS_FEDERATION:
            return labels.allegiance.pilotsFederation
        case ALLEGIANCE_THARGOID:
            return labels.allegiance.thargoids
        default:
            return `${labels.allegiance.unknown} (${id})`
    }
}

export function getAllegianceBadgeUrlById(id) {
    switch(id) {
        case ALLEGIANCE_ALLIANCE:
            return allianceBadgeUrl
        case ALLEGIANCE_EMPIRE:
            return empireBadgeUrl
        case ALLEGIANCE_FEDERATION:
            return federationBadgeUrl
        case ALLEGIANCE_PILOTS_FEDERATION:
            return pilotsBadgeUrl
        case ALLEGIANCE_THARGOID:
            return thargoidBadgeUrl
        case ALLEGIANCE_INDEPENDENT:
        default:
            return independentBadgeUrl
    }
}

export function getAllegianceCutoutUrlById(id) {
    switch(id) {
        case ALLEGIANCE_ALLIANCE:
            return allianceCutoutUrl
        case ALLEGIANCE_EMPIRE:
            return empireCutoutUrl
        case ALLEGIANCE_FEDERATION:
            return federationCutoutUrl
        case ALLEGIANCE_PILOTS_FEDERATION:
            return pilotsCutoutUrl
        case ALLEGIANCE_THARGOID:
            return thargoidCutoutUrl
        case ALLEGIANCE_INDEPENDENT:
        default:
            return independentCutoutUrl
    }
}

export function isAlliance(id) {
    return id === ALLEGIANCE_ALLIANCE
}

export function isEmpire(id) {
    return id === ALLEGIANCE_EMPIRE
}

export function isFederation(id) {
    return id === ALLEGIANCE_FEDERATION
}

export function isIndependent(id) {
    return id === ALLEGIANCE_INDEPENDENT
}
