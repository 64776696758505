import React from 'react'
import './StationList.css'

import { getStationTypeNameById, getStationIconById, isFleetCarrier } from '../../../helpers/StationHelper'

/**
 * This component displays a list of stations ordered by distance from the main star,
 * including icons for each station type, relevant subtexts and an Inara link for fleet carriers
 */
class StationList extends React.Component {

    render() {
        if (this.props.stations.length > 0) {
            return <ul className="station-list">
                {this.props.stations.sort((a, b) => a.distanceToStarLs - b.distanceToStarLs).map(s => this.renderStation(s))}
            </ul>
        } else {
            return <></>
        }
    }

    renderStation(station) {
        let backgroundStyle = this.props.color != null ? { background: this.props.color } : {},
            foregroundStyle = this.props.color != null ? { color: this.props.color } : {}

        let content = <>
            <div className="station-list-icon-container has-primary-background" style={backgroundStyle}>
                <img src={getStationIconById(station.typeId)} alt={getStationTypeNameById(station.typeId)} className="station-list-icon" />
            </div>
            <p className="station-list-name" style={foregroundStyle}>
                {station.name}
                <span className="subtext"> ({this.createSubtext(station)})</span>
            </p>
        </>

        let inaraLink = isFleetCarrier(station) ? this.createInaraCarrierLink(station.name) : this.createInaraStationLink(this.props.system.name, station.name)

        return <li key={station.id} className="station-list-item">
            <a href={inaraLink} className="station-list-carrier-link" target="_blank" rel="noreferrer">
                {content}
            </a>
        </li>
    }

    createSubtext(station) {
        if (station.distanceToStarLs > 9999) {
            return `${Math.round(station.distanceToStarLs / 1000)}k ls`
        } else if (station.distanceToStarLs > 999) {
            return `${(station.distanceToStarLs / 1000).toFixed(1)}k ls`
        } else {
            return `${station.distanceToStarLs} ls`
        }
    }

    createInaraCarrierLink(carrierCallsign) {
        return `https://inara.cz/galaxy-station/?search=${carrierCallsign}`
    }

    createInaraStationLink(systemName, stationName) {
        return `https://inara.cz/galaxy-station/?search=${this.htmlEscape(systemName)}%20[${this.htmlEscape(stationName)}]`
    }

    htmlEscape(query) {
        return query.split(' ').join('%20')
    }
}

export default StationList
