import React from 'react'
import './Error.css'

import { logError, parseMessage } from '../../helpers/LoggerHelper'

/**
 * This component renders an error message and then calls onHide() to tell its parent to make it disappear
 */
class Error extends React.Component {

    render() {
        logError(this.props.message)
        console.log(this.props.message)
        
        return <div
            className="error-message"
            style={{ animationDuration: `${this.getAirTimeMs() / 1000}s` }}>
            {parseMessage(this.props.message)}
        </div>
    }

    getAirTimeMs() {
        return 2000
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.onHide()
        }, this.getAirTimeMs())
    }
}

export default Error