import React from 'react'
import './FactionRoster.css'

import StationList from '../station-list/StationList'

import { getColorForFactionRank, isInfluenceGrowing, isInfluenceDropping, getInfluenceChangePercentageString } from '../../../helpers/InfluenceHelper'
import { getAllegianceNameById, getAllegianceCutoutUrlById } from '../../../helpers/AllegianceHelper'
import { getStateNameById, isNoState, hasConflictState, isConflictState, getConflictOpponent } from '../../../helpers/StateHelper'

import influenceUpIcon from '../../../assets/images/influence_up.png'
import influenceDownIcon from '../../../assets/images/influence_down.png'

/**
 * This component renders a list of faction, and stations, ordered ownership and painted in nice colours
 */
class FactionRoster extends React.Component {

    render() {
        return <div className="faction-roster">
            {this.props.system.factions.map(f => this.renderFaction(f))}
        </div>
    }

    renderFaction(faction) {
        let color = getColorForFactionRank(faction.id, this.props.system.factions),
            hasConflict = hasConflictState(faction.activeStates),
            glowableClass = `roster-faction-glowable ${hasConflict ? 'has-conflict-animation' : ''}`,
            nameClass = `roster-faction-name ${faction.id === this.props.selectedFaction.id ? 'emphasize' : ''}`

        return <div key={faction.id} className="roster-faction">
            <img src={getAllegianceCutoutUrlById(faction.allegianceId)} alt={getAllegianceNameById(faction.allegianceId)} className="roster-faction-allegiance" style={{ background: color }} />
            {this.renderInfluenceGrowth(faction)}
            <div className={glowableClass} onClick={() => this.props.onFactionClicked(faction)}>
                <div className="roster-faction-influence" style={{ background: color, width: `${faction.influence}%` }}></div>
                <p className={nameClass} style={{ color: color }}>
                    {this.renderPercentagePrefix(faction.influence, faction.previousInfluence)} {faction.name}
                    {this.renderStateSuffix(faction.activeStates, faction, color)}
                </p>
            </div>

            {this.renderStations(faction, color)}
        </div >
    }

    renderInfluenceGrowth(faction) {
        let delta = getInfluenceChangePercentageString(faction)

        if (isInfluenceGrowing(faction)) {
            return <img src={influenceUpIcon} alt={delta} className="roster-faction-influence-growth-icon" />
        } else if (isInfluenceDropping(faction)) {
            return <img src={influenceDownIcon} alt={delta} className="roster-faction-influence-growth-icon" />
        } else {
            return <></>
        }
    }

    renderStations(faction, color) {
        let ourStations = this.props.system.stations.filter(s => s.controllingFactionId === faction.id)
        return <StationList stations={ourStations} system={this.props.system} color={color} ></StationList>
    }

    renderPercentagePrefix(influence, previousInfluence) {
        let rounded = Math.round(influence),
            delta = previousInfluence ? (influence - previousInfluence).toFixed(2) : 0

        return <span className="roster-faction-percentage">
            [{rounded >= 10 ? rounded : '0' + rounded}% <span className="change">{delta >= 0 ? '+' : ''}{delta}%</span>]
        </span>
    }

    renderStateSuffix(states, faction, color) {
        let relevantStates = states.filter(s => !isNoState(s))

        if (relevantStates.length === 0) {
            return <></>
        } else {
            let statesAsString = relevantStates.map(s => {
                if (isConflictState(s)) {
                    let stateName = getStateNameById(s)
                    let opponent = getConflictOpponent(faction, this.props.system.factions)

                    if (opponent != null)
                        return `${stateName} vs ${opponent.name}`
                    else
                        return stateName
                } else {
                    return getStateNameById(s)
                }
            }).reduce((a, b) => `${a}, ${b}`)

            return <span className="roster-faction-state" style={{ background: color }}>
                {statesAsString}
            </span>
        }
    }
}

export default FactionRoster
