import React from 'react'
import './SpecialStationList.css'

import * as labels from '../../../assets/labels.json'

import { getSpecialStations } from '../../../helpers/StationHelper'
import StationList from '../station-list/StationList'

/**
 * This component displays special stations (fleet carriers, megaships), re-using the StationList component
 */
class SpecialStationList extends React.Component {

    render() {
        let specialStations = getSpecialStations(this.props.system)

        return <div className="special-station-list">
            <p className="special-station-list-header emphasize">{labels.galaxyMap.systemView.specialStationPresence}</p>
            <StationList stations={specialStations} system={this.props.system}></StationList>
        </div>
    }
}

export default SpecialStationList
