import React from 'react'
import './Clock.css'

import * as labels from '../../../assets/labels.json'

const MONTH_NAMES = ['JAN', 'FEB', 'MAR', 'APR', "MAY", 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

/**
 * This component renders the current Elite: Dangerous date and time
 */
class Clock extends React.Component {

    updateInterval

    constructor() {
        super()

        this.state = {
            time: this.getTimeAsString(),
            date: this.getDateAsString()
        }
    }

    render() {
        return <div className="clock desktop-only">
            <p>{this.state.time}</p>
            <p>{this.state.date}</p>
            <p className="subtext">{labels.galaxyMap.sidebar.dataUpdatesAtX}</p>
        </div>
    }

    componentDidMount() {
        this.updateInterval = setInterval(() => {
            this.setState({ time: this.getTimeAsString() })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval)
    }

    getDateAsString() {
        let currentDate = new Date()

        return `
            ${toDisplayUnit(currentDate.getUTCDate())} 
            ${MONTH_NAMES[currentDate.getUTCMonth()]} 
            ${toEliteDangerousYear(currentDate.getUTCFullYear())}
        `
    }

    getTimeAsString() {
        let currentTime = new Date()

        return `
            ${toDisplayUnit(currentTime.getUTCHours())}:
            ${toDisplayUnit(currentTime.getUTCMinutes())}:
            ${toDisplayUnit(currentTime.getUTCSeconds())}
        `
    }
}

function toEliteDangerousYear(actualYear) {
    return actualYear + 1286
}

function toDisplayUnit(unit) {
    if (unit < 10) {
        return `0${unit}`
    } else {
        return unit
    }
}

export default Clock