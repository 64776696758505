import React from 'react'
import './SystemView.css'

import AllegianceGovernmentBadge from '../allegiance-government-badge/AllegianceGovernmentBadge'
import FactionRoster from '../faction-roster/FactionRoster'
import SpecialStationList from '../special-station-list/SpecialStationList'

import { systemHasCapitalShips, getFactionsWithCapitalShips } from '../../../helpers/CapitalShipHelper'
import { getCombatAnnouncements } from '../../../helpers/AnnouncementHelper'
import { getTradeAnnouncementsForEconomy } from '../../../helpers/EconomyHelper'
import { getPopulationAnnouncement } from '../../../helpers/PopulationHelper'
import { hasSpecialStations } from '../../../helpers/StationHelper'
import { isEmpire, isFederation } from '../../../helpers/AllegianceHelper'

import empireCapitalShipImage from '../../../assets/images/capital_ship_majestic_left.png'
import federationCapitalShipImage from '../../../assets/images/capital_ship_farragut_left.png'
import bothCapitalShipsImage from '../../../assets/images/capital_ship_both_left.png'

import * as labels from '../../../assets/labels.json'

/**
 * This component shows system info after its tile is clicked, and houses the General, Combat, Trade and Exploration tabs
 */
class SystemView extends React.Component {

    constructor() {
        super()
        this.state = { tab: 0 }
    }

    render() {
        return <div className="system-view" onClick={() => this.onBackgroundClicked(this)}>
            <div className="system-view-window" onClick={event => this.onWindowClicked(event, this)}>
                <h2 className="system-view-name">{this.props.system.name}</h2>
                <p className="system-view-updated-at">{labels.galaxyMap.systemView.lastUpdatedAt} <span className="emphasize">{this.getTimestampForUnixEpoch(this.props.system.updatedAt)}</span></p>
                <hr />
                <nav className="system-view-navigation">
                    <button onClick={() => this.openTab(0, this)} className={this.getClassNameForTab(0)}>{labels.galaxyMap.systemView.general}</button>
                    <button onClick={() => this.openTab(1, this)} className={this.getClassNameForTab(1)}>{labels.galaxyMap.systemView.combat}</button>
                    <button onClick={() => this.openTab(2, this)} className={this.getClassNameForTab(2)}>{labels.galaxyMap.systemView.trade}</button>
                    <button onClick={() => this.openTab(3, this)} className={this.getClassNameForTab(3)}>{labels.galaxyMap.systemView.exploration}</button>
                </nav>
                <hr />
                {this.renderGeneralSection()}
                {this.renderCombatSection()}
                {this.renderTradeSection()}
                {this.renderExplorationSection()}
                <hr />
                <button onClick={() => this.onCloseClicked(this)} className="system-view-close">{labels.galaxyMap.systemView.close}</button>
            </div>
        </div>
    }

    renderGeneralSection() {
        if (this.state.tab === 0) {
            let controllingFaction = this.props.system.factions.find(f => f.isControllingFaction)
            let populationAnnouncement = getPopulationAnnouncement(this.props.system.population)

            let controllingFactionNote = controllingFaction != null ? <>{labels.galaxyMap.systemView.becauseOf} <b>{controllingFaction.name}</b></> : <>{labels.galaxyMap.systemView.noControllingFaction}</>

            return <>
                <ul className="system-view-announcements">
                    {this.renderAnnouncement({
                        name: <AllegianceGovernmentBadge allegianceId={this.props.system.allegianceId} governmentId={this.props.system.governmentId}></AllegianceGovernmentBadge>,
                        note: controllingFactionNote
                    })}
                    {this.renderAnnouncement(populationAnnouncement)}
                </ul>
                {this.renderSpecialStations()}
                <hr />
                <FactionRoster
                    system={this.props.system}
                    selectedFaction={this.props.selectedFaction}
                    onFactionClicked={faction => this.props.onFactionClicked(faction)}>
                </FactionRoster>
            </>
        } else {
            return <></>
        }
    }

    renderSpecialStations() {
        if (hasSpecialStations(this.props.system)) {
            return <>
                <hr />
                <SpecialStationList system={this.props.system}></SpecialStationList>
            </>
        } else {
            return <></>
        }
    }

    renderCombatSection() {
        if (this.state.tab === 1) {
            let combatAnnouncements = getCombatAnnouncements(this.props.system),
                isCapitalShipsPresent = systemHasCapitalShips(this.props.system)

            return <>
                <ul>
                    {combatAnnouncements.map(this.renderAnnouncement)}
                </ul>
                { isCapitalShipsPresent ? this.renderCapitalShips() : <></>}
            </>
        } else {
            return <></>
        }
    }

    renderCapitalShips() {
        let factions = getFactionsWithCapitalShips(this.props.system.factions),
            image = this.getCapitalShipImage(factions)

        return <>
            <hr />
            <div className="system-view-capital-ships">
                <img src={image} alt={labels.states.war} className="system-view-capital-ships-image" />
                <p className="system-view-capital-ships-note">
                    {labels.announcements.capitalShipPresenceNote}
                    <ul className="system-view-capital-ships-note-factions">
                        {factions.map(f => <li key={f.id} className="system-view-capital-ships-note-faction">{f.name}</li>)}
                    </ul>
                </p>
            </div>
        </>
    }

    getCapitalShipImage(factions) {
        if (factions.some(f => isEmpire(f.allegianceId)) && factions.some(f => isFederation(f.allegianceId))) {
            return bothCapitalShipsImage
        } else if (factions.some(f => isEmpire(f.allegianceId))) {
            return empireCapitalShipImage
        } else {
            return federationCapitalShipImage
        }
    }

    renderTradeSection() {
        if (this.state.tab === 2) {
            return <>
                <ul>
                    {getTradeAnnouncementsForEconomy(this.props.system.economyId).map(this.renderAnnouncement)}
                </ul>
            </>
        } else {
            return <></>
        }
    }

    renderExplorationSection() {
        if (this.state.tab === 3) {
            return <p>{labels.galaxyMap.systemView.noExplorationActivities}</p>
        } else {
            return <></>
        }
    }

    renderAnnouncement(announcement) {
        return <li key={announcement.id} className="system-view-announcement">
            <p className="system-view-announcement-title emphasize">{announcement.name}</p>
            <br />
            <span className="system-view-announcement-subtext">
                {announcement.note}
            </span>
        </li>
    }

    openTab(which, context) {
        context.setState({
            tab: which
        })
    }

    getTimestampForUnixEpoch(epoch) {
        let returnValue = new Date(0);
        returnValue.setUTCSeconds(epoch)
        return returnValue.toUTCString()
    }

    getClassNameForTab(which) {
        if (which === this.state.tab) {
            return 'selected'
        } else {
            return ''
        }
    }

    onBackgroundClicked(context) {
        context.onCloseClicked(context)
    }

    onCloseClicked(context) {
        context.props.onClosed()
    }

    onWindowClicked(event, context) {
        event.stopPropagation()
    }
}

export default SystemView
