import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Navigator.css'

import logo from '../../assets/images/logo_light.png'
import marketIcon from '../../assets/images/tab_market.png'
import marketIconDark from '../../assets/images/tab_market_dark.png'
import aboutIcon from '../../assets/images/tab_about.png'
import aboutIconDark from '../../assets/images/tab_about_dark.png'
import galaxyMapIcon from '../../assets/images/tab_galaxy.png'
import galaxyMapIconDark from '../../assets/images/tab_galaxy_dark.png'
import * as labels from '../../assets/labels.json'

import { galaxyMapUrl } from '../galaxy-map/GalaxyMap'
import { stockMarketUrl } from '../stock-market/StockMarket'
import { aboutUrl } from '../about/About'

function Navigator(props) {
    let path = useLocation().pathname

    return <nav className="navigator has-button-background">
        <img src={logo} alt="logo" className="navigator-logo" />
        <p className="navigator-app-title">{labels.app.name}</p>

        {createButton({
            label: labels.navigation.galaxyMap,
            url: galaxyMapUrl,
            currentPath: path,
            icon: galaxyMapIcon,
            darkIcon: galaxyMapIconDark
        })}
        {createButton({
            label: labels.navigation.stockMarket,
            url: stockMarketUrl,
            currentPath: path,
            icon: marketIcon,
            darkIcon: marketIconDark
        })}
        {createButton({
            label: labels.navigation.about,
            url: aboutUrl,
            currentPath: path,
            icon: aboutIcon,
            darkIcon: aboutIconDark,
            desktopOnly: true
        })}
    </nav>
}

function createButton(options) {
    let selected = options.currentPath.indexOf(options.url) === 0,
        className = `navigator-button ${selected ? 'selected' : ''} ${options.desktopOnly ? 'desktop-only' : ''}`

    return <Link to={options.url}>
        <button className={className}>
            { !selected? <img src={options.icon} alt={options.label} className="navigator-button-icon" /> : <></> }
            <img src={options.darkIcon} alt={options.label} className="navigator-button-icon-dark" />
            {options.label}
        </button>
    </Link>
}

export default Navigator
