import React from 'react'
import './Loader.css'
import darkLogo from '../../assets/images/logo_dark.png'
import lightLogo from '../../assets/images/logo_light.png'

/**
 * This component displays the Elite logo whenever triggered
 */
class Loader extends React.Component {

    render() {
        return <div className="loader">
            <div className="loader-dark-logo" style={{backgroundImage: `url('${darkLogo}')`}} />
            {this.renderLoaderImage()}
        </div>
    }

    renderLoaderImage() {
        if (this.props.isLoading) {
            return <div className="loader-light-logo" style={{backgroundImage: `url('${lightLogo}')`}} />
        } else {
            return <></>
        }
    }
}

export default Loader
