import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import './App.css'

import { GalaxyMap, galaxyMapUrl, galaxyMapWithVariableUrl } from './galaxy-map/GalaxyMap'
import { StockMarket, stockMarketUrl } from './stock-market/StockMarket'
import { About, aboutUrl } from './about/About'

import Navigator from './navigator/Navigator'

class App extends React.Component {

  render() {
    return <div className="app">
      <BrowserRouter>
        <div className="app-navigator-container">
          <Navigator></Navigator>
        </div>
        <div className="app-router-target">
          <Switch>
            <Route path={galaxyMapWithVariableUrl} render={props => <GalaxyMap searchInput={props.match.params.searchInput}></GalaxyMap>} />
            <Route path={galaxyMapUrl}>
              <GalaxyMap></GalaxyMap>
            </Route>
            <Route path={stockMarketUrl}>
              <StockMarket></StockMarket>
            </Route>
            <Route path={aboutUrl}>
              <About></About>
            </Route>
            <Redirect exact from="/" to={stockMarketUrl} />
          </Switch>
        </div>
      </BrowserRouter>
    </div >
  }
}

export default App
