import * as labels from '../assets/labels.json'

export const STATE_WAR = 73
export const STATE_CIVIL_WAR = 64
export const STATE_EXPANSION = 67
export const STATE_INVESTMENT = 101
export const STATE_BOOM = 16
export const STATE_BUST = 32
export const STATE_FAMINE = 37
export const STATE_ELECTION = 65
export const STATE_PIRATE_ATTACK = 81
export const STATE_CIVIL_LIBERTY = 66
export const STATE_LOCKDOWN = 69
export const STATE_CIVIL_UNREST = 48
export const STATE_OUTBREAK = 72
export const STATE_RETREAT = 96
export const STATE_BLIGHT = 102
export const STATE_INFRASTRUCTURE_FAILURE = 104
export const STATE_DROUGHT = 103
export const STATE_PUBLIC_HOLIDAY = 106
export const STATE_TERRORIST_ATTACK = 107
export const STATE_NONE = 80

export const ALERT_HIGH_IMPORTANCE_BACKGROUND = '#B80707'
export const ALERT_HIGH_IMPORTANCE_COLOR = '#FFF'
export const ALERT_MED_IMPORTANCE_BACKGROUND = '#FF9900'
export const ALERT_MED_IMPORTANCE_COLOR = '#000'
export const ALERT_LOW_IMPORTANCE_BACKGROUND = '#C7C7C7'
export const ALERT_LOW_IMPORTANCE_COLOR = '#000'

export function getStateNameById(id) {
    switch (id) {
        case STATE_WAR:
            return labels.states.war
        case STATE_CIVIL_WAR:
            return labels.states.civilWar
        case STATE_ELECTION:
            return labels.states.election
        case STATE_EXPANSION:
            return labels.states.expansion
        case STATE_INVESTMENT:
            return labels.states.investment
        case STATE_PIRATE_ATTACK:
            return labels.states.pirateAttack
        case STATE_TERRORIST_ATTACK:
            return labels.states.terroristAttack
        case STATE_OUTBREAK:
            return labels.states.outbreak
        case STATE_CIVIL_LIBERTY:
            return labels.states.civilLiberty
        case STATE_CIVIL_UNREST:
            return labels.states.civilUnrest
        case STATE_RETREAT:
            return labels.states.retreat
        case STATE_BLIGHT:
            return labels.states.blight
        case STATE_LOCKDOWN:
            return labels.states.lockdown
        case STATE_BOOM:
            return labels.states.boom
        case STATE_BUST:
            return labels.states.bust
        case STATE_FAMINE:
            return labels.states.famine
        case STATE_DROUGHT:
            return labels.states.drought
        case STATE_INFRASTRUCTURE_FAILURE:
            return labels.states.infrastructureFailure
        case STATE_PUBLIC_HOLIDAY:
            return labels.states.publicHoliday
        case STATE_NONE:
            return labels.states.none
        default:
            return `${labels.states.unknown} (${id})`
    }
}

export function getStateIdByName(name) {
    switch (name.toLowerCase()) {
        case labels.states.war.toLowerCase():
            return STATE_WAR
        case labels.states.civilWar.toLowerCase():
            return STATE_CIVIL_WAR
        case labels.states.election.toLowerCase():
            return STATE_ELECTION
        case labels.states.expansion.toLowerCase():
            return STATE_EXPANSION
        case labels.states.investment.toLowerCase():
            return STATE_INVESTMENT
        case labels.states.pirateAttack.toLowerCase():
            return STATE_PIRATE_ATTACK
        case labels.states.terroristAttack.toLowerCase():
            return STATE_TERRORIST_ATTACK
        case labels.states.outbreak.toLowerCase():
            return STATE_OUTBREAK
        case labels.states.civilLiberty.toLowerCase():
            return STATE_CIVIL_LIBERTY
        case labels.states.civilUnrest.toLowerCase():
            return STATE_CIVIL_UNREST
        case labels.states.retreat.toLowerCase():
            return STATE_RETREAT
        case labels.states.blight.toLowerCase():
            return STATE_BLIGHT
        case labels.states.lockdown.toLowerCase():
            return STATE_LOCKDOWN
        case labels.states.boom.toLowerCase():
            return STATE_BOOM
        case labels.states.bust.toLowerCase():
            return STATE_BUST
        case labels.states.famine.toLowerCase():
            return STATE_FAMINE
        case labels.states.drought.toLowerCase():
            return STATE_DROUGHT
        case labels.states.infrastructureFailure.toLowerCase():
            return STATE_INFRASTRUCTURE_FAILURE
        case labels.states.publicHoliday.toLowerCase():
            return STATE_PUBLIC_HOLIDAY
        case labels.states.none.toLowerCase():
            return STATE_NONE
        default:
            return -1
    }
}

/**
 * Return a list of alerts regarding the given active, pending or recovering states, ordered by priority
 */
export function getAlertsForStates(activeStates, pendingStates, recoveringStates) {
    let returnValue = []

    if (has(activeStates, STATE_RETREAT)) returnValue.push(alert(labels.alerts.ongoingRetreat, ALERT_HIGH_IMPORTANCE_COLOR, ALERT_HIGH_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_WAR)) returnValue.push(alert(labels.alerts.ongoingWar, ALERT_HIGH_IMPORTANCE_COLOR, ALERT_HIGH_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_CIVIL_WAR)) returnValue.push(alert(labels.alerts.ongoingCivilWar, ALERT_HIGH_IMPORTANCE_COLOR, ALERT_HIGH_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_ELECTION)) returnValue.push(alert(labels.alerts.ongoingElection, ALERT_HIGH_IMPORTANCE_COLOR, ALERT_HIGH_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_INFRASTRUCTURE_FAILURE)) returnValue.push(alert(labels.alerts.ongoingInfraFailure, ALERT_MED_IMPORTANCE_COLOR, ALERT_MED_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_EXPANSION)) returnValue.push(alert(labels.alerts.ongoingExpansion, ALERT_MED_IMPORTANCE_COLOR, ALERT_MED_IMPORTANCE_BACKGROUND))
    if (has(pendingStates, STATE_RETREAT)) returnValue.push(alert(labels.alerts.impendingRetreat, ALERT_MED_IMPORTANCE_COLOR, ALERT_MED_IMPORTANCE_BACKGROUND))
    if (has(pendingStates, STATE_WAR)) returnValue.push(alert(labels.alerts.impendingWar, ALERT_MED_IMPORTANCE_COLOR, ALERT_MED_IMPORTANCE_BACKGROUND))
    if (has(pendingStates, STATE_CIVIL_WAR)) returnValue.push(alert(labels.alerts.impendingCivilWar, ALERT_MED_IMPORTANCE_COLOR, ALERT_MED_IMPORTANCE_BACKGROUND))
    if (has(pendingStates, STATE_ELECTION)) returnValue.push(alert(labels.alerts.impendingElection, ALERT_MED_IMPORTANCE_COLOR, ALERT_MED_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_PIRATE_ATTACK)) returnValue.push(alert(labels.alerts.ongoingPirateAttack, ALERT_LOW_IMPORTANCE_COLOR, ALERT_LOW_IMPORTANCE_BACKGROUND))
    if (has(activeStates, STATE_OUTBREAK)) returnValue.push(alert(labels.alerts.ongoingOutbreak, ALERT_LOW_IMPORTANCE_COLOR, ALERT_LOW_IMPORTANCE_BACKGROUND))

    return returnValue
}

export function getDistinctStatesInSystem(system, factionId) {
    let factions = factionId ? system.factions.filter(f => f.id === factionId) : system.factions

    return factions
        .flatMap(f => f.activeStates)
        .filter((s, i, self) => {
            return self.indexOf(s) === i
        })
}

export function hasConflictState(states) {
    return states.some(isConflictState)
}

export function isConflictState(state) {
    switch (state) {
        case STATE_WAR:
        case STATE_CIVIL_WAR:
        case STATE_ELECTION:
            return true;
        default:
            return false;
    }
}

/**
 * Return another faction with the same influence as us
 */
export function getConflictOpponent(us, them) {
    return them.filter(f => f.id !== us.id).find(f => f.influence === us.influence)
}

export function isNoState(state) {
    return state === STATE_NONE
}

/**
 * Return a list of announcements based on a list of states that may be relevant for combat activities
 */
export function getCombatAnnouncementsForStates(states) {
    let returnValue = []

    if (hasWarState(states)) {
        returnValue.push(getWarAnnouncement(states))
    }

    if (states.indexOf(STATE_PIRATE_ATTACK) > -1) {
        returnValue.push({
            id: STATE_PIRATE_ATTACK,
            name: labels.states.pirateAttack,
            note: labels.announcements.pirateAttackCombatNote
        })
    }

    return returnValue
}

export function getWarringFactions(factions) {
    return factions.filter(f => hasWarState(f.activeStates))
}

export function hasWarState(states) {
    return states.some(s => s === STATE_WAR || s === STATE_CIVIL_WAR)
}

function getWarAnnouncement(states) {
    if (states.indexOf(STATE_WAR) > -1) {
        return {
            id: STATE_WAR,
            name: labels.states.war,
            note: labels.announcements.warCombatNote
        }
    } else if (states.indexOf(STATE_CIVIL_WAR) > -1) {
        return {
            id: STATE_CIVIL_WAR,
            name: labels.states.civilWar,
            note: labels.announcements.warCombatNote
        }
    } else {
        return null
    }
}

function has(states, id) {
    return states.some(s => s === id)
}

function alert(text, color, background) {
    return {
        text: text,
        color: color,
        background: background
    }
}
