export function hasStored(key) {
    return localStorage.getItem(key) != null
}

export function getFromStorage(key) {
    return JSON.parse(localStorage.getItem(key))
}

export function store(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
}

