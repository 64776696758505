import React from 'react'
import './FactionStats.css'

import { getTotalInfluencedPopulation, formatPopulation } from '../../../helpers/PopulationHelper'
import * as labels from '../../../assets/labels.json'

import AllegianceGovernmentBadge from '../allegiance-government-badge/AllegianceGovernmentBadge'

class FactionStats extends React.Component {

    render() {
        return <div className="faction-stats desktop-only">
                <h2 className="faction-label">{this.props.faction.name || labels.galaxyMap.sidebar.unknownFaction}</h2>
                <ul className="faction-stats-list">
                    <li>
                        <AllegianceGovernmentBadge 
                            allegianceId={this.props.faction.allegianceId} 
                            governmentId={this.props.faction.governmentId}>
                            </AllegianceGovernmentBadge>
                    </li>
                    <li>{labels.galaxyMap.sidebar.homeSystem} <b className="faction-home-system emphasize" onClick={() => this.onHomeSystemClicked(this)}>{this.getFactionHomeSystemName() || labels.galaxyMap.sidebar.unknown}</b></li>
                    <li>{this.insertIntoLabel(<b key={10} className="emphasize">{this.getFactionPresence() || '0'}</b>, labels.galaxyMap.sidebar.presentInXSystems)}</li>
                    <li>{this.insertIntoLabel(<b key={10} className="emphasize">{this.getFactionControlledSystemCount() || '0'}</b>, labels.galaxyMap.sidebar.inControlOfXSystems)}</li>
                    <li>{labels.galaxyMap.sidebar.populationUnderInfluence} <b className="emphasize">{this.getInfluencedPopulation()}</b></li>
                </ul>
            </div>
    }

    getFactionHomeSystemName() {
        if (this.props.faction.systems != null && this.props.faction.systems.length > 0) {
            let match = this.props.faction.systems.find(s => s.id === this.props.faction.homeSystemId)

            if (match != null && match.name != null) {
                return match.name
            } else {
                return labels.galaxyMap.sidebar.unknown
            }
        } else {
            return labels.galaxyMap.sidebar.unknown
        }
    }

    getFactionPresence() {
        if (this.props.faction.systems != null && this.props.faction.systems.length > 0) {
            return this.props.faction.systems.length
        } else {
            return 0
        }
    }

    getFactionControlledSystemCount() {
        if (this.props.faction.systems != null && this.props.faction.systems.length > 0) {
            return this
                .props
                .faction.systems
                .filter(system => system
                    .factions
                    .some(faction => faction.isControllingFaction && faction.id === this.props.faction.id))
                .length
        } else {
            return 0
        }
    }

    getInfluencedPopulation() {
        let total = getTotalInfluencedPopulation(this.props.faction, this.props.faction.systems)
        return formatPopulation(total)
    }

    insertIntoLabel(value, label) {
        let key = 0
        let parts = label.split('@{x}').map(l => <span key={key++}>{l}</span>)
        return [parts[0], value, parts[1]]
    }

    onHomeSystemClicked(context) {
        let system = context.props.faction.systems.find(s => s.id === context.props.faction.homeSystemId)

        if (system != null)
            context.props.onSystemSelected(system)

    }
}

export default FactionStats
