import React from 'react'
import './About.css'

import * as labels from '../../assets/labels.json'
import * as aboutText from '../../assets/about.json'

export const aboutUrl = '/about'

export class About extends React.Component {

    render() {
        return <div className="about-container">
            <h1 className="about-title">{labels.app.name}</h1>
            <p className="about-text">{this.parseLines(aboutText.lines)}</p>
            <p className="about-eddb-link">
                <a href="https://eddb.io/" >{labels.app.eddb}</a>
            </p>
        </div>
    }

    parseLines(lines) {
        let key = 0

        return lines.map(l => {
            if (l === '\n') {
                return <br key={key++}/>
            } else {
                return <span key={key++}>{l}</span>
            }
        })
    }
}
