import * as environments from '../environments.json'

const cache = {}

export function select(faction) {
    if (faction != null) {
        let encoded = htmlEncode(faction)

        if (cache[encoded] != null) {
            return Promise.resolve(cache[encoded])
        } else {
            let url = `${environments.local.apiGateway}/selections?faction=${encoded}`

            return new Promise((resolve, reject) => {
                fetch(url).then(res => {
                    if (res.status > 199 && res.status < 300) {
                        res.json().then(result => {
                            cache[encoded] = result
                            resolve(result)
                        }).catch(reject)
                    } else {
                        res.json().then(reject).catch(reject)
                    }
                }).catch(reject)
            })
        }
    } else {
        return Promise.reject(`Bad faction parameter: ${faction}`)
    }
}

export function getTopFactions() {
    if (cache.topFactions != null) {
        return Promise.resolve(cache.topFactions)
    } else {
        let url = `${environments.local.apiGateway}/stock-market`

        return new Promise((resolve, reject) => {
            fetch(url).then(res => {
                if (res.status > 199 && res.status < 300) {
                    res.json().then(result => {
                        cache.topFactions = result
                        resolve(result)
                    }).catch(reject)
                } else {
                    res.json().then(reject).catch(reject)
                }
            }).catch(reject)
        })
    }
}

// Wolf 406 Transport & Co
function htmlEncode(raw) {
    return raw
        .split(' ').join('%20')
        .split('+').join('%2B')
        .split('/').join('%2F')
        .split('\'').join('%27')
        .split('&').join('%26')
        .split('"').join('%22')
}
