import * as labels from '../assets/labels.json'

export function getPopulationAnnouncement(population) {
    if (population > 1000000000) { // More than 1 Billion
        return getAnnouncement(labels.population.veryHighPopulation, population, labels.announcements.influenceManipulationVeryHard)
    } else if (population > 1000000) { // More than 1 Million
        return getAnnouncement(labels.population.highPopulation, population, labels.announcements.influenceManipulationHarder)
    } else if (population > 100000) {
        return getAnnouncement(labels.population.mediumPopulation, population, labels.announcements.influenceManipulationNormal)
    } else if (population > 10000) { // More than 10 Thousand
        return getAnnouncement(labels.population.lowPopulation, population, labels.announcements.influenceManipulationEasier)
    } else { // Less than 10 Thousand
        return getAnnouncement(labels.population.veryLowPopulation, population, labels.announcements.influenceManipulationVeryEasy)
    }
}

export function getTotalInfluencedPopulation(faction, systems) {
    if (systems != null && systems.length > 0) {
        return systems.map(s => {
            let factionInSystem = s.factions.find(f => f.id === faction.id)

            if (factionInSystem != null) {
                let influence = factionInSystem.influence
                return s.population * (influence / 100)
            } else {
                return 0
            }
        }).reduce((a, b) => a + b)
    } else {
        return 0
    }
}

export function formatPopulation(population) {
    if (population > 999999999) { // Is in Billions
        return `${(population / 1000000000).toFixed(2)} Bil`
    } else if (population > 999999) { // Is in Millions
        return `${(population / 1000000).toFixed(2)} Mil`
    } else if (population > 999) { // Is in Thousands
        return `${(population / 1000).toFixed(2)} K`
    } else {
        return population.toFixed(2)
    }
}


function getAnnouncement(size, pop, note) {
    return {
        id: pop,
        name: `${size} (${formatPopulation(pop)})`,
        note: note
    }
}
