import * as labels from '../assets/labels.json'

const ECONOMY_AGRICULTURE = 1
const ECONOMY_EXTRACTION = 2
const ECONOMY_HIGH_TECH = 3
const ECONOMY_INDUSTRIAL = 4
const ECONOMY_MILITARY = 5
const ECONOMY_REFINERY = 6
const ECONOMY_SERVICE = 7
const ECONOMY_TERRAFORMING = 8
const ECONOMY_TOURISM = 9
const ECONOMY_NONE = 10
const ECONOMY_COLONY = 11

export function getEconomyNameById(id) {
    switch (id) {
        case ECONOMY_AGRICULTURE:
            return labels.economy.agriculture
        case ECONOMY_EXTRACTION:
            return labels.economy.extraction
        case ECONOMY_HIGH_TECH:
            return labels.economy.highTech
        case ECONOMY_INDUSTRIAL:
            return labels.economy.industrial
        case ECONOMY_MILITARY:
            return labels.economy.military
        case ECONOMY_REFINERY:
            return labels.economy.refinery
        case ECONOMY_SERVICE:
            return labels.economy.service
        case ECONOMY_TERRAFORMING:
            return labels.economy.terraforming
        case ECONOMY_TOURISM:
            return labels.economy.tourism
        case ECONOMY_COLONY:
            return labels.economy.colony
        case ECONOMY_NONE:
            return labels.economy.noEconomy
        default:
            return `${labels.economy.unknown} (${id})`
    }
}

export function getEconomyIdByName(name) {
    switch (name.toLowerCase()) {
        case labels.economy.agriculture.toLowerCase():
            return ECONOMY_AGRICULTURE
        case labels.economy.extraction.toLowerCase():
            return ECONOMY_EXTRACTION
        case labels.economy.highTech.toLowerCase():
            return ECONOMY_HIGH_TECH
        case labels.economy.industrial.toLowerCase():
            return ECONOMY_INDUSTRIAL
        case labels.economy.military.toLowerCase():
            return ECONOMY_MILITARY
        case labels.economy.refinery.toLowerCase():
            return ECONOMY_REFINERY
        case labels.economy.service.toLowerCase():
            return ECONOMY_SERVICE
        case labels.economy.terraforming.toLowerCase():
            return ECONOMY_TERRAFORMING
        case labels.economy.tourism.toLowerCase():
            return ECONOMY_TOURISM
        case labels.economy.colony.toLowerCase():
            return ECONOMY_COLONY
        case labels.economy.none.toLowerCase():
            return ECONOMY_NONE
        default:
            return -1
    }
}

export function getTradeAnnouncementsForEconomy(id) {
    let returnValue = [{
        id: id,
        name: getEconomyNameById(id),
        note: getTradeNoteById(id)
    }]

    if (id === ECONOMY_HIGH_TECH) {
        returnValue.push({
            id: id + 100,
            name: labels.announcements.shipsAndModules,
            note: labels.announcements.highTechSystemNote
        })
    }

    return returnValue
}

function getTradeNoteById(id) {
    switch (id) {
        case ECONOMY_AGRICULTURE:
            return labels.announcements.agricultureTradeNote
        case ECONOMY_EXTRACTION:
            return labels.announcements.extractionTradeNote
        case ECONOMY_HIGH_TECH:
            return labels.announcements.highTechTradeNote
        case ECONOMY_INDUSTRIAL:
            return labels.announcements.industrialTradeNote
        case ECONOMY_MILITARY:
            return labels.announcements.militaryTradeNote
        case ECONOMY_REFINERY:
            return labels.announcements.refineryTradeNote
        case ECONOMY_SERVICE:
            return labels.announcements.serviceTradeNote
        case ECONOMY_TERRAFORMING:
            return labels.announcements.terraformingTradeNote
        case ECONOMY_TOURISM:
            return labels.announcements.tourismTradeNote
        case ECONOMY_COLONY:
            return labels.announcements.colonyTradeNote
        case ECONOMY_NONE:
            return labels.announcements.noEconomyTradeNote
        default:
            return `${labels.economy.unknown} (${id})`
    }
}
