import { getWarringFactions } from './StateHelper'
import { isEmpire, isFederation } from './AllegianceHelper'

export function systemHasCapitalShips(system) {
    return getFactionsWithCapitalShips(system.factions).length > 0
}

export function getFactionsWithCapitalShips(factions) {
    return getWarringFactions(factions).filter(f => isEmpire(f.allegianceId) || isFederation(f.allegianceId))
}
