import React from 'react'

import { getAllegianceNameById, getAllegianceBadgeUrlById } from '../../../helpers/AllegianceHelper'
import { getGovernmentNameById } from '../../../helpers/GovernmentHelper'

import * as labels from '../../../assets/labels.json'
import './AllegianceGovernmentBadge.css'

/**
 * This component renders an allegiance badge icon (Empire/Fed/Alliance) with a government label
 */
class AllegianceGovernmentBadge extends React.Component {

    render() {
        return <b className="allegiance-badge">
            <img 
                src={getAllegianceBadgeUrlById(this.props.allegianceId)} 
                alt={getAllegianceNameById(this.props.allegianceId) || labels.allegiance.noAllegiance} />
            <p className="emphasize">{getGovernmentNameById(this.props.governmentId) || labels.government.unknownGovernment}</p>
        </b>
    }
}

export default AllegianceGovernmentBadge
