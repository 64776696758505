const SYSTEM_LABEL_COLOR_CONTROLLING_HIGH = '#56FF74'
const SYSTEM_LABEL_COLOR_CONTROLLING_MEDIUM_HIGH = '#D9FF54'
const SYSTEM_LABEL_COLOR_CONTROLLING_MEDIUM = '#FFE068'
const SYSTEM_LABEL_COLOR_CONTROLLING_LOW_MEDIUM = '#FF9854'
const SYSTEM_LABEL_COLOR_CONTROLLING_LOW = '#FF5656'

/**
 * Return the color for the given faction vs other factions based on the given faction's influence there
 */
export function getColorForFactionRank(factionId, factions) {
    const rankAsPercentage = getFactionRankAsPercentageInSystem(factionId, factions)

    if (rankAsPercentage < 20) { 
        return SYSTEM_LABEL_COLOR_CONTROLLING_LOW
    } else if (rankAsPercentage < 40) {
        return SYSTEM_LABEL_COLOR_CONTROLLING_LOW_MEDIUM
    } else if (rankAsPercentage < 60) {
        return SYSTEM_LABEL_COLOR_CONTROLLING_MEDIUM
    } else if (rankAsPercentage < 80) {
        return SYSTEM_LABEL_COLOR_CONTROLLING_MEDIUM_HIGH
    } else {
        return SYSTEM_LABEL_COLOR_CONTROLLING_HIGH
    }
}

export function getColorForStockMarket(faction) {
    if (faction.influence > faction.previousInfluence) {
        return SYSTEM_LABEL_COLOR_CONTROLLING_HIGH
    } else if (faction.influence < faction.previousInfluence) {
        return SYSTEM_LABEL_COLOR_CONTROLLING_LOW
    } else {
        return SYSTEM_LABEL_COLOR_CONTROLLING_MEDIUM
    }
}

export function isInfluenceGrowing(faction) {
    return faction.influence > faction.previousInfluence
}

export function isInfluenceDropping(faction) {
    return faction.influence < faction.previousInfluence
}

export function getInfluenceChangePercentageString(faction) {
    return `${(faction.influence - faction.previousInfluence).toFixed(2)}%`
}

export function getGlobalInfluenceChangePercentageString(faction) {
    if (faction.influence != null && faction.influence > 0 && faction.previousInfluence != null && faction.previousInfluence > 0)
        return `${(((faction.influence / faction.previousInfluence) * 100) - 100).toFixed(2)} %`
    else
        return '0.00 %'
}

/**
 * Return a number between 0-100 of the rank of the given faction vs the other factions in terms of influence
 * The factions will be sorted by influence descending
 */
function getFactionRankAsPercentageInSystem(factionId, factions) {
    const sortedFactions = sortFactionsByInfluence(factions)
    
    let selectedFactionRank = 0
    for (let i = 0; i < sortedFactions.length; i++) {
        if (sortedFactions[i].id === factionId) {
            selectedFactionRank = (sortedFactions.length - 1) - i
            break
        }
    }

    // This is the % of a single faction out of all factions, so 25% for 4 factions
    const margin = 100 / sortedFactions.length

    // If it is the lowest ranked faction, this value will be 0.
    // If it is the highest ranked, this value will be 100 - the margin
    const unstretchedRank = (100 * selectedFactionRank) / sortedFactions.length

    // This value is the max-min rank range given the number of factions
    // (so would be 75% for 4 factions because the lowest faction would have rank 25%)
    const range = 100 - margin

    // This value is the unstretched rank mapped to 0-100%
    let stretchedRank = Math.ceil((100 * unstretchedRank) / range)
    if (stretchedRank > 100) {
        stretchedRank = 100
    }

    // We want to return the rank in the range of 0-100%
    return stretchedRank
}

function sortFactionsByInfluence(factions) {
    return factions.sort((a, b) => {
        if (a.influence < b.influence)
            return 1
        else if (b.influence < a.influence)
            return -1
        else
            return 0
    })
}